import React from "react";

const HTMLEmbedView = ({ html, css, section_id }) => {
  return (
    html && (
      <section id={section_id}>
        <style dangerouslySetInnerHTML={{ __html: css }} />
        <div dangerouslySetInnerHTML={{ __html: html }}></div>
      </section>
    )
  );
};

export default HTMLEmbedView;
